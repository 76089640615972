<script>
import Layout from "../layouts/main";
import { api } from "@/state/services";
import { mapState } from "vuex";
import Banner from "@/components/widgets/banner";
import Stat from "@/components/widgets/stat";
import TradingViewTv from "@/components/widgets/chart-tradingview-tv";
import TradingViewTicker from '@/components/widgets/chart-tradingview-ticker.vue';

import firebase from "firebase/app";
import "firebase/messaging";

export default {
  locales: {
    pt: {
      'Team': 'Time',
      'Dashboard': 'Painel',
      'Plan': 'Plano',
      'Package': 'Pacote',
      'Graduation': 'Graduação',
      'Points': 'Pontos',
      'Referral link': 'Link de indicação',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',
      'No records found.': 'Nenhum registro encontrado.',
      'Total Bonus': 'Total de Bônus',
      'Total Points': 'Total de Pontos',
    },
    es: {
      'Team': 'Equipo',
      'Dashboard': 'Panel',
      'Plan': 'Plano',
      'Package': 'Paquete',
      'Graduation': 'Graduación',
      'Points': 'Puntos',
      'Referral link': 'Link de patrocineo',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.',
      'No records found.': 'No se encontraron registros.',
      'Total Bonus': 'Total de Bônus',
      'Total Points': 'Total de Puntos',
    }
  },
  components: {
    Layout,
    Banner,
    Stat,
    TradingViewTicker,
    TradingViewTv,
  },
  computed: {
    ...mapState({
      account: state => state.account
    }),
  },
  data() {
    return {
      total: {
        contracts: "R$ -,--",
      },

      statData: null,

      notifications: true,

      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2
      },
    };
  },
  methods: {
    getTotalContracts() {
      api
        .get('contract/total')
        .then(response => {
          if (response.data.status=='success') {
            this.total.contracts = this.$options.filters.currency(response.data.total)
            this.getStatData();
          }
        })
    },
    getStatData() {
      this.statData = [
        {
          type: 'center',
          icon: "package",
          title: "Cliente",
          value: this.account.user.name,
        },
        {
          type: 'center',
          icon: 'contracts',
          title: 'Meus Contratos',
          value: this.total.contracts,
        },
      ]
    },
    checkNotifications() {
      if (firebase.messaging.isSupported()) {
        api
          .get('user/notifications')
          .then(response => {
            if (response.data.status==='success') {
              if (response.data.devices == 'active') {
                this.notifications = true
              } else {
                this.notifications = false
              }
            } else {
              this.notifications = false
            }
          });
      }
    },
  },
  mounted() {
    this.getTotalContracts()
    this.getStatData()
  }
};
</script>

<template>
  <Layout>
    <router-link v-if="!notifications" to="/account/notifications" class="d-none text-dark">
      <div class="card">
        <div class="card-body bg-soft-info rounded">
          <div class="d-flex align-items-center">
            <i class="bx bx-bell font-size-24 align-middle mr-3"></i>
            <div>Fique informado de todas as atualizações do seu escritório virtual, ative suas notificações Web Push!</div>
          </div>
        </div>
      </div>
    </router-link>

    <Banner></Banner>

    <div class="row">
      <div v-for="stat of statData" :key="stat.icon" :class="account.user.package.access == 'limited' ? 'col-md-4' : 'col-md-6 col-lg-6 col-xl-3'">
        <Stat :type="stat.type" :icon="stat.icon" :title="stat.title" :value="stat.value" />
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 col-lg-12">
        <div class="card">
          <div class="card-body p-4">
            <TradingViewTicker class="mb-3"></TradingViewTicker>
            <TradingViewTv></TradingViewTv>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="this.account.user.package.access == 'full'">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h5 class="mb-2">
              <i class="bx bx-link mr-1"></i> {{ t('Referral link') }}
            </h5>
            <p class="m-0 notranslate">
              https://app.udyatcorp.com/{{ account.user.username }}
              <button class="btn btn-link p-0 ml-2" v-clipboard:copy="'https://app.udyatcorp.com/' + account.user.username"><i class="bx bx-copy font-size-18 text-dark"></i></button>
            </p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.contract {
  border-left: solid 5px #010101;
}
.contract-code {
  line-height: 14px;
}

.table-profit {
  line-height: 15px;
}
.table-profit th,
.table-profit td {
  white-space: nowrap;
}
.table-year th {
  background: #BEBFC1;
}
.table-month th {
  background: #D2D3D4;
}
</style>