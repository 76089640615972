<template>
  <div>
    <VueTradingView :options="{
      width: '100%',
      height: 500,
      symbol: 'NASDAQ:AAPL',
      theme: 'light',
      interval: 'D',
      timezone: 'exchange',
      theme: 'light',
      withdateranges: true,
      hide_side_toolbar: true,
      allow_symbol_change: true,
      save_image: false,
      show_popup_button: false,
      locale: 'br'
    }" />
  </div>
</template>

<script>
import VueTradingView from 'vue-trading-view';

export default {
  components: {
    VueTradingView,
  }
}
</script>